import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const TeamManagementPage = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/index`} />
    <Route path={`${match.url}/index`} component={asyncComponent(() => import('./index'))} />
    <Route path={`${match.url}/learner/:user_id/view`} component={asyncComponent(() => import('./Learner/page'))} />
  </Switch>
);
export default TeamManagementPage;

