import React from "react";
import ReactDOM from "react-dom";

import NextApp from './NextApp';
import registerServiceWorker from './registerServiceWorker';
// Add this import:
import {AppContainer} from 'react-hot-loader';
import axios from 'axios';

axios.interceptors.request.use(request => {
  const accessToken = localStorage.getItem('token');
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  }
  return request;
}, error => {
  // console.log('REQUEST Error', error);
  // console.log('ERROR STATUS', error.response.status);
  return error;
});

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401 || error.response.status === 403) {
    localStorage.clear();
    window.location.href = '/';
  }
  return error.response;
});


// Wrap the rendering in a function:
const render = Component => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <Component/>
    </AppContainer>,
    document.getElementById('root')
  );
};

// Do this once
registerServiceWorker();

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./NextApp', () => {
    render(NextApp);
  });
}
