export function getLetterName(name) {
  let thLetter = ['เ', 'แ', 'ใ', 'ไ', 'โ'];
  let letter1 = name.charAt(0).toUpperCase();
  let letter2 = name.charAt(1).toUpperCase();
  if (thLetter.find(item => item === letter1)) {
    return letter2;
  }
  return letter1;
}

export function getRegexForValidateEng() {
  return new RegExp('^[a-zA-Z][a-zA-Z0-9-_ ]*[a-zA-Z0-9]$');
}

export function getRegexForValidateNumber() {
  return new RegExp('^[0-9]*[0-9]$');
}

export function getRegexForValidateEngTh() {
  return new RegExp('^[a-zA-Z0-9\u0E01-\u0E2E\u0E40-\u0E44][a-zA-Z0-9\u0E01-\u0E5B-_ ]*[a-zA-Z0-9\u0E01-\u0E5B]$');
}

export function getRegexForValidateEmail() {
  return new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/);
}

export function slugify(text) {
  return text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-');
}

export function parseJwt(token) {
  let split = token.split('.');
  let payload = JSON.parse(atob(split[1]));
  return payload;
}

export function btoa(input) {
  return Buffer.from(input).toString('base64');
}

export function atob(input) {
  return Buffer.from(input, 'base64').toString();
}
