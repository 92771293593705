import antdTH from "antd/lib/locale-provider/th_TH";
import thMessages from "../locales/th_TH.json";

const thLang = {
  messages: {
    ...thMessages
  },
  antd: antdTH,
  locale: 'th'
};
export default thLang;
