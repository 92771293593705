import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

import Roles from './Roles/routes';
import Permissions from './Permissions/routes';
import Users from './Users/routes';
import BookCategory from './BookCategorys/routes';
import Books from './Book/routes';
import Courses from './Courses/routes';
// import Compulsorys from './Compulsory/routes';
// import PreTrainings from './Courses/routes';
// import RequestTraining from './RequestTraining/routes';

const Settings = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}roles`} />
    <Route path={`${match.url}/roles`} component={Roles} />
    <Route path={`${match.url}/permissions`} component={Permissions} />
    <Route path={`${match.url}/users`} component={Users} />
    <Route path={`${match.url}/book-categorys`} component={BookCategory} />
    <Route path={`${match.url}/books`} component={Books} />
    <Route path={`${match.url}/courses`} component={Courses} />
    
    
  </Switch>
);

export default Settings;

// <Route path={`${match.url}/compulsorys`} component={Compulsorys} />
// <Route path={`${match.url}/pretraining`} component={PreTrainings} />
// <Route path={`${match.url}/request-training`} component={RequestTraining} />