import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { onNavStyleChange, toggleCollapsedSideNav } from "appRedux/actions/Setting";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";


const SidebarLogo = () => {
  const dispatch = useDispatch();
  const { width, themeType } = useSelector(({ settings }) => settings);
  const { navCollapsed } = useSelector(({ common }) => common);

  const [imagePath, setImagePath] = useState(process.env.REACT_APP_IMAGE_DEV_PATH);

  const onLoad = async () => {
    if (process.env.REACT_APP_ENV === 'production') {
      setImagePath(process.env.REACT_APP_IMAGE_PROD_PATH);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    onLoad();
    const cleanup = () => {
      abortController.abort();
    }
    return cleanup;
  }, []);

  let navStyle = useSelector(({ settings }) => settings.navStyle);
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }
  return (
    <div className="gx-layout-sider-header">
      {(navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) ? <div className="gx-linebar">
        <i
          className={`gx-icon-btn icon icon-${navStyle === NAV_STYLE_MINI_SIDEBAR ? 'menu-unfold' : 'menu-fold'} ${themeType !== THEME_TYPE_LITE ? 'gx-text-white' : ''}`}
          onClick={() => {
            if (navStyle === NAV_STYLE_DRAWER) {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            } else if (navStyle === NAV_STYLE_FIXED) {
              dispatch(onNavStyleChange(NAV_STYLE_MINI_SIDEBAR))
            } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            } else {
              dispatch(onNavStyleChange(NAV_STYLE_FIXED))
            }
          }}
        />
      </div> : null}

      <Link to="/lms/homepages" className="gx-site-logo">
        {navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR && width >= TAB_SIZE ?
          <img alt="lo" src={`${imagePath}/static-image/default_image_logo.png`} /> :
          themeType === THEME_TYPE_LITE ?
            <img alt="logo1" src={`${imagePath}/static-image/default_image_logo.png`} /> :
            <img alt="logo2" src={`${imagePath}/static-image/default_image_logo.png`} />}
      </Link>
    </div>
  );
};

// require("assets/custom-images/img-logo-ntl.svg")

export default SidebarLogo;
