import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import URLSearchParams from 'url-search-params'
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ConfigProvider } from 'antd';
import { IntlProvider } from "react-intl";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import AdfsLogin from '../AdfsLogin';
import { setInitUrl, userSignOut, userSignInSuccess } from "appRedux/actions/Auth";
import { onLayoutTypeChange, onNavStyleChange, setThemeType } from "appRedux/actions/Setting";
import { parseJwt } from 'util/Helpers';
import * as moment from 'moment-timezone';

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK
} from "../../constants/ThemeSetting";

const RestrictedRoute = ({ component: Component, location, authUser, accessToken, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: { from: location }
          }}
        />}
  />;


const App = (props) => {

  const dispatch = useDispatch();
  const { locale, themeType, navStyle, layoutType, themeColor } = useSelector(({ settings }) => settings);
  let { authUser, initURL } = useSelector(({ auth }) => auth);
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    let link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = `/css/${themeColor}.css`;  //This line is changed, this comment is for explaination purpose.

    link.className = 'gx-style';
    document.body.appendChild(link);
  }, []);


  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
    const params = new URLSearchParams(location.search);

    if (params.has("theme")) {
      dispatch(setThemeType(params.get('theme')));
    }
    if (params.has("nav-style")) {
      dispatch(onNavStyleChange(params.get('nav-style')));
    }
    if (params.has("layout-type")) {
      dispatch(onLayoutTypeChange(params.get('layout-type')));
    }
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  });


  const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  const setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  useEffect(() => {
    let accessToken = localStorage.getItem('token');
    let logged = false;
    if ((accessToken !== undefined && accessToken !== "" && accessToken !== null) && (authUser !== undefined && authUser !== "" && authUser !== null)) {
        logged = true;
    } else if (!(accessToken !== undefined && accessToken !== "" && accessToken !== null) && (authUser !== undefined && authUser !== "" && authUser !== null)) {
        logged = true;
        accessToken = authUser;
        localStorage.setItem(accessToken);
        dispatch(userSignInSuccess(accessToken));
    } else if ((accessToken !== undefined && accessToken !== "" && accessToken !== null) && !(authUser !== undefined && authUser !== "" && authUser !== null)) {
        logged = true;
        dispatch(userSignInSuccess(accessToken));
    } else {
        logged = false;
    }

    if (logged) {
        if (accessToken !== null) {
          setAccessToken(accessToken);
          dispatch(userSignInSuccess(accessToken));
        }

        const { data } = parseJwt(accessToken);
        const dateExpired = moment(data.expiredDate).tz('Asia/Bangkok');
        const dateNow = moment().tz('Asia/Bangkok');
        // alert("xxx : " + dateExpired.isAfter(dateNow, 'second'));
        // if (!dateExpired.isAfter(dateNow, 'second')) {
        //   logged = false;
        //   dispatch(userSignOut());
        // }
    }

    // let logged = false;
    // if (accessToken !== undefined && accessToken !== "" && accessToken !== null) {
    //   logged = true;
    //   const { data } = parseJwt(accessToken);
    //   const dateExpired = moment(data.expiredDate).tz('Asia/Bangkok');
    //   const dateNow = moment().tz('Asia/Bangkok');
    //   if (!dateExpired.isAfter(dateNow, 'second')) {
    //     logged = false;
    //     dispatch(userSignOut());
    //   }
    // }

    // if (logged) {
    //   if (authUser === null) {
    //     dispatch(userSignInSuccess(accessToken));
    //   }
    // }

    if (accessToken !== "" && authUser === "") {
      authUser = accessToken;
    }

    if (location.pathname === '/') {
      if (authUser === null) {
        history.push('/signin');
      } else if (initURL === '' || initURL === '/' || initURL === '/signin' || initURL === '/adfs') {
        history.push('/lms/homepages');
      } else {
        history.push(initURL);
      }
    }

  }, [authUser, initURL, location, history, accessToken]);


  useEffect(() => {
    if (themeType === THEME_TYPE_DARK) {
      console.log("adding dark class")
      document.body.classList.add('dark-theme');
      document.body.classList.add('dark-theme');
      let link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = "/css/dark_theme.css";
      link.className = 'style_dark_theme';
      document.body.appendChild(link);
    }
  }
    , []);

  const currentAppLocale = AppLocale[locale.locale];
  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>

        <Switch>
          <Route exact path='/signin' component={SignIn} />
          <Route exact path={`/adfs`} component={AdfsLogin} />
          <RestrictedRoute path={`${match.url}`} authUser={authUser} accessToken={accessToken} location={location}
            component={MainApp} />
        </Switch>

      </IntlProvider>
    </ConfigProvider>

  )
};

export default memo(App);
