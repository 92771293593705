import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ActivateRole from './ActivateRole/routes';
import NotActivateRole from './NotActivateRole/routes';

const Users = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/activate-role`} />
    <Route path={`${match.url}/activate-role`} component={ActivateRole} />
    <Route path={`${match.url}/not-activate-role`} component={NotActivateRole} />
  </Switch>
);
export default Users;

