import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

import CourseGroups from './CourseGroups/routes';
import CourseTypes from './CourseTypes/routes';
import CourseProgram from './CourseProgram/routes';
import CourseItemType from './CourseItemType/routes';
import CourseDelivaryMethod from './CourseDelivaryMethod/routes';
import CourseCompetency from './CourseCompetency/routes';
import CourseSubCompetency from './CourseSubCompetency/routes';
import CourseSpeaker from './CourseSpeaker/routes';

const RTCMasterPage = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/index`} />
    <Route path={`${match.url}/index`} component={asyncComponent(() => import('./index'))} />
    <Route path={`${match.url}/group`} component={CourseGroups} />
    <Route path={`${match.url}/type`} component={CourseTypes} />
    <Route path={`${match.url}/program`} component={CourseProgram} />
    <Route path={`${match.url}/item-type`} component={CourseItemType} />
    <Route path={`${match.url}/delivary-method`} component={CourseDelivaryMethod} />
    <Route path={`${match.url}/competency`} component={CourseCompetency} />
    <Route path={`${match.url}/subcompetency`} component={CourseSubCompetency} />
    <Route path={`${match.url}/speaker`} component={CourseSpeaker} />
  </Switch>
);






export default RTCMasterPage;