import React from 'react';
import { Input } from 'antd';
const SearchBox = ({ styleName, placeholder, onChange, onSearch, onPressEnter, value }) => {
  return (
    <div className={`gx-search-bar ${styleName}`}>
      <div className="gx-form-group">
        <Input.Search
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          onSearch={onSearch}
          onPressEnter={onPressEnter}
          allowClear
          enterButton
        />
      </div>
    </div>
  );
};
export default SearchBox;

SearchBox.defaultProps = {
  styleName: '',
  value: ''
};
