import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Avatar, message } from 'antd';
import axios from 'axios';
import IntlMessages from "util/IntlMessages";
import { UserOutlined } from '@ant-design/icons';
import _ from 'underscore';


const BASE_API_OWNER_PROFILE = process.env.REACT_APP_UPLOAD_ENDPOINT + '/profile';

const UserProfile = () => {
  const dispatch = useDispatch();
 
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  const onLoad = async () => {
    setLoading(true);
    await axios.get(`${BASE_API_OWNER_PROFILE}/me`).then(res => {
      if (res['data'] != undefined && res['data']['error']) {
        throw res.data.error;
      }

      
      let data = res['data'];
      if (data != undefined && data.length > 0) {
        let content = data[0];
        content.custom_group_location = (content.batch_employees_info.location === "Head Office")? "HO" : "BRANCH";
        content.custom_fullname_th = `${content.batch_employees_info.title_th}${content.batch_employees_info.firstname_th} ${content.batch_employees_info.lastname_th}`;
        
        content.custom_ho_learning_level = content.learning_level_name;
        content.custom_branch_learning_level = `${content.career_level_name} ${content.learning_level_name}`;

        content.job_title_th = content.batch_employees_info.job_title_th;

        setDataSource(content);
        setLoading(false);
      }
    })
    .catch(error => {
      if (error['code'] && error['message']) {
        showError(`(${error['code']}) ${error['message']}`);
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    const abortController = new AbortController();
    onLoad();
    const cleanup = () => {
      abortController.abort();
    }
    return cleanup;
  }, []);


  const showSuccess = (feedbackMessage) => {
    message.success(feedbackMessage);
  };

  const showError = (feedbackMessage) => {
      message.error(feedbackMessage);
  };



  return (

    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Avatar shape="square" src={"https://via.placeholder.com/150"}
        className="gx-size-60 shape gx-pointer gx-mr-3 avatar-employee" alt="" />

      <ul className="gx-user-display">
        <li>
          <span className="gx-avatar-role">
          <IntlMessages id="littleprofile.top_left.user_id" /> : {dataSource.user_id}</span>
        </li>
        <li>
          <span className="gx-avatar-name custom-profile-info">
            <b><IntlMessages id="littleprofile.top_left.firstname_lastname" /> :</b> {dataSource.custom_fullname_th}
          </span>
        </li>
        <li>
          <span className="gx-avatar-role">
          <IntlMessages id="littleprofile.top_left.position" /> :  {dataSource.job_title_th}</span>
        </li>
      </ul>
    </div>

  )
};

export default UserProfile;
