import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Input, message, Form } from "antd";
import Icon from '@ant-design/icons';
import { Link, useHistory } from "react-router-dom";
import { getRegexForValidateEmail } from 'util/Helpers';

import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";
import { useDispatch, useSelector } from "react-redux";
import {
  hideMessage,
  showAuthLoader,
  userSignIn,
  userSignInSuccess,
} from "appRedux/actions/Auth";
import { parseJwt } from 'util/Helpers';
import * as moment from 'moment-timezone';

const SignIn = () => {

  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(({ auth }) => auth);
  const [form] = Form.useForm();
  const history = useHistory();
  const [useADFS, setUseADFS] = useState(process.env.REACT_APP_ASFS_USE);

  const onLoad = () => {
    let accessToken = localStorage.getItem('token');
    if (!(accessToken === null || accessToken === "")) {
      dispatch(userSignInSuccess(accessToken));
      if (authUser !== null) {
          history.push('/');
      }
    } else {
      if (useADFS === 'true' && (accessToken === null || accessToken === "")) {
        window.location.href = process.env.REACT_APP_ASFS_ENDPOINT;
      } else {
          if (showMessage) {
              setTimeout(() => {
                  dispatch(hideMessage());
              }, 100);
          }
      
          if (authUser !== null) {
              history.push('/');
          }
      
          form.setFieldsValue({
              remember_me: true
          })
      }
    }
  }

  useEffect(() => {
    onLoad();
  });


  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = values => {
    dispatch(showAuthLoader());
    dispatch(userSignIn(values));
  };

  return (
    <div className="gx-app-login-wrap">
        { (useADFS === 'false') && <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">

              <img src={"https://via.placeholder.com/272x395"} alt='Neature' />
            </div>
            <div className="gx-app-logo-wid">
              <h1><IntlMessages id="app.userAuth.signIn" /> Version 0.2</h1>
              <p><IntlMessages id="page.login.bySigning" /></p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src={require("assets/custom-images/img-logo-ntl.svg")} />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">

              <Form.Item
                initialValue="CF100012"
                rules={[{ required: true, message: 'The input is not valid Employee Username!' }]} name="username">
                <Input placeholder="Employee Username" />
              </Form.Item>
              <Form.Item
                initialValue="Mali.B@ngerntidlor.com"
                rules={[
                  { required: true, message: 'Please input Employee Email!' },
                  {
                    pattern: getRegexForValidateEmail(),
                    message: 'Please enter corrected e-mail format.'
                  },
                  { max: 200, message: 'Employee Email is not more than 200 characters.' }
                ]} name="password">
                <Input placeholder="Employee Email" />
              </Form.Item>
              <Form.Item name="remember_me" valuePropName="checked">
                <Checkbox><IntlMessages id="page.login.iAcceptForRememberMe" /></Checkbox>
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="page.login.BTN.signIn" />
                </Button>
              </Form.Item>
              <span
                className="gx-text-light gx-fs-sm"> demo user email: 'demo@example.com' and password: 'demo#123'</span>
            </Form>
          </div>

          {loader ?
            <div className="gx-loader-view">
              <CircularProgress />
            </div> : null}
          {showMessage ?
            message.error(alertMessage.toString()) : null}
        </div>
      </div>}
    </div>
  );
};

export default SignIn;


