import {all, call, delay, fork, put, takeEvery} from "redux-saga/effects";
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Col, Row, message, Modal } from "antd";
import axios from 'axios';
import moment from 'moment';
import _ from 'underscore';
import CircularProgress from "components/CircularProgress/index";
import {
  hideMessage,
  showAuthLoader,
  userSignIn,
} from "appRedux/actions/Auth";

import {showAuthMessage, userSignInSuccess} from "appRedux/actions/Auth";

const BASE_API_ADFS = process.env.REACT_APP_UPLOAD_ENDPOINT + '/adfs';

const ADFSLoginPage = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  let { search } = useLocation();
  const [loader, setLoader] = useState(true);

  const onLoad = async () => {
    let queryStatus = false;
    let accessToken = localStorage.getItem('token');
    let paramToken = "";
    if (search !== "") {
      const searchParams = new URLSearchParams(search);
      paramToken = searchParams.get('token');
      if (paramToken !== "") {
        queryStatus = true;
      }
    }

    if (queryStatus && paramToken !== "") {
      await axios.post(`${BASE_API_ADFS}/verify/token`, { token: paramToken }).then(res => {
        if (res['data'] != undefined && res['data']['error']) {
          throw res.data.error;
        }
        
        if (res.data) {
          localStorage.setItem('token', res.data.accessToken);
          dispatch(userSignInSuccess(res.data.accessToken));
          history.push('/lms/homepages');
        }
      })
      .catch(error => {
        if (error['code'] && error['message']) {
          Modal.error({
            title: 'System Message',
            content: `(${error['code']}) ${error['message']}`,
            onOk() {
              window.location.href = process.env.REACT_APP_ASFS_ENDPOINT;
            },
          });
        }
      }).finally(() => {
        setLoader(false);
      });

    } else {
      if (!(accessToken === null || accessToken === "")) {
        window.location.href = "/lms/homepages";
      } else {
        window.location.href = process.env.REACT_APP_ASFS_ENDPOINT;
      }
    }
  }

  useEffect(() => {
    const abortController = new AbortController();
    onLoad();
    const cleanup = () => {
      abortController.abort();
    }
    return cleanup;
  }, []);

  const showSuccess = (feedbackMessage) => {
      message.success(feedbackMessage);
  };

  const showError = (feedbackMessage) => {
      message.error(feedbackMessage);
  };

  const delayTime = (delay) => {
    return new Promise( res => setTimeout(res, delay) );
  }


  return (
    <div className="gx-main-content gx-pb-sm-4">
      {loader ?
            <div className="gx-loader-view">
              <CircularProgress />
            </div> : null}
    </div>
  );
};

export default ADFSLoginPage;