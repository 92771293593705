import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const DashboardPage = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/course`} />
    <Route path={`${match.url}/course`} component={asyncComponent(() => import('./Courses/page'))} />
    <Route path={`${match.url}/elibrary`} component={asyncComponent(() => import('./ELibrary/page'))} />
  </Switch>
);
export default DashboardPage;

// <Route path={`${match.url}/e-learning`} component={asyncComponent(() => import('./Elearning/page'))} />
