import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import { parseJwt } from 'util/Helpers';
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";

import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "util/IntlMessages";
import { useSelector } from "react-redux";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = () => {

  let { pathname } = useSelector(({ common }) => common);
  let { navStyle, themeType } = useSelector(({ settings }) => settings);

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };
  let selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  if (selectedKeys.indexOf("lms/settings/roles") === 0) {
    selectedKeys = "lms/settings/roles";
  }

  if (selectedKeys.indexOf("lms/settings/permissions") === 0) {
    selectedKeys = "lms/settings/permissions";
  }

  if (selectedKeys.indexOf("lms/settings/users/not-activate-role") === 0 || selectedKeys.indexOf("lms/settings/users/activate-role") === 0) {
    selectedKeys = "lms/settings/users";
  }

  if (selectedKeys.indexOf("lms/settings/book-categorys") === 0) {
    selectedKeys = "lms/settings/book-categorys";
  }

  if (selectedKeys.indexOf("lms/settings/books") === 0) {
    selectedKeys = "lms/settings/books";
  }

  if (selectedKeys.indexOf("lms/settings/compulsorys/courses") === 0) {
    selectedKeys = "lms/settings/compulsorys/courses";
  }

  if (selectedKeys.indexOf("lms/settings/pretraining/courses") === 0) {
    selectedKeys = "lms/settings/pretraining/courses";
  }

  if (selectedKeys.indexOf("lms/settings/request-training/group") === 0) {
    selectedKeys = "lms/settings/request-training/group";
  }
  
  if (selectedKeys.indexOf("lms/settings/request-training/type") === 0) {
    selectedKeys = "lms/settings/request-training/type";
  }
  
  if (selectedKeys.indexOf("lms/settings/request-training/program") === 0) {
    selectedKeys = "lms/settings/request-training/program";
  }

  if (selectedKeys.indexOf("lms/settings/request-training/item-type") === 0) {
    selectedKeys = "lms/settings/request-training/item-type";
  }

  if (selectedKeys.indexOf("lms/settings/request-training/program") === 0) {
    selectedKeys = "lms/settings/request-training/program";
  }
  
  if (selectedKeys.indexOf("lms/settings/request-training/delivary-method") === 0) {
    selectedKeys = "lms/settings/request-training/delivary-method";
  }

  if (selectedKeys.indexOf("lms/settings/request-training/competency") === 0) {
    selectedKeys = "lms/settings/request-training/competency";
  }

  if (selectedKeys.indexOf("lms/settings/request-training/subcompetency") === 0) {
    selectedKeys = "lms/settings/request-training/subcompetency";
  }

  if (selectedKeys.indexOf("lms/settings/request-training/speaker") === 0) {
    selectedKeys = "lms/settings/request-training/speaker";
  }

  if (selectedKeys.indexOf("lms/dashbaord/course") === 0) {
    selectedKeys = "lms/dashbaord/course";
  }

  if (selectedKeys.indexOf("lms/dashbaord/elibrary") === 0) {
    selectedKeys = "lms/dashbaord/elibrary";
  }
  
  if (selectedKeys.indexOf("lms/team-mgnt") === 0) {
    selectedKeys = "lms/team-mgnt/index";
  }

  if (selectedKeys.indexOf("lms/settings/courses/master") === 0) {
    selectedKeys = "lms/settings/courses/master";
  }

  if (selectedKeys.indexOf("lms/settings/courses/compulsory") === 0) {
    selectedKeys = "lms/settings/courses/compulsorys";
  }

  if (selectedKeys.indexOf("lms/settings/courses/pretraining") === 0) {
    selectedKeys = "lms/settings/courses/pretraining";
  }

  if (selectedKeys.indexOf("lms/settings/courses/new-staff") === 0) {
    selectedKeys = "lms/settings/courses/new-staff";
  }

  if (selectedKeys.indexOf("lms/settings/courses/other") === 0) {
    selectedKeys = "lms/settings/courses/other";
  }

  
  
  
  let accessToken = localStorage.getItem('token');
  const { data } = (accessToken) ? parseJwt(accessToken) : { data : { permission: []}};
  const { permission } = data;

  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile />
          <AppsNavigation />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">

            {
              permission && (
                permission.includes("AR_HOME_PAGE_LAYOUT_A") || 
                permission.includes("AR_HOME_PAGE_LAYOUT_B") || 
                permission.includes("AR_HOME_PAGE_LAYOUT_C") || 
                permission.includes("AR_HOME_PAGE_LAYOUT_D") || 
                permission.includes("AR_HOME_PAGE_LAYOUT_E") || 
                permission.includes("AR_HOME_PAGE_LAYOUT_F")
              ) ?
                  <Menu.Item key="lms/homepages">
                    <Link to="/lms/homepages"><i className="icon icon-home" />
                      <span><IntlMessages id="menu.left.home_page" /></span></Link>
                  </Menu.Item>
                : null
            }

            {
              permission && (
                permission.includes("AR_PRETRAINING_COURSELIST_TAB") ||
                permission.includes("AR_PRETRAINING_MYCOURSE_TAB") 
              ) ?
                <Menu.Item key="lms/pre-training">
                  <Link to="/lms/pre-training"><i className="icon icon-wysiwyg" />
                    <span><IntlMessages id="menu.left.pretraining" /></span></Link>
                </Menu.Item>
              : null
            }

            {
              permission && (
                permission.includes("AR_COMPULSORY_COURSELIST_TAB") ||
                permission.includes("AR_COMPULSORY_MYCOURSE_TAB") 
              ) ?
                <Menu.Item key="lms/compulsory">
                  <Link to="/lms/compulsory"><i className="icon icon-graduation" />
                    <span><IntlMessages id="menu.left.compulsory" /></span></Link>
                </Menu.Item>
              : null
            }

            {
              permission && (
                permission.includes("AR_LEARINING_JOURNEY_GAME_PAGE")
              ) ?
                <Menu.Item key="lms/learning-journey">
                  <Link to="/lms/learning-journey"><i className="icon icon-graduation" />
                    <span><IntlMessages id="menu.left.learning_journey" /></span></Link>
                </Menu.Item>
                : null
            }

            {
              permission && (
                permission.includes("AR_LIBRARY_BOOKLIST_TAB") ||
                permission.includes("AR_LIBRARY_MYBOOK_TAB") ||                 
                permission.includes("AR_LIBRARY_LIBRARION_TAB")
              ) ?
                <Menu.Item key="lms/elibrary">
                  <Link to="/lms/elibrary"><i className="icon icon-apps" />
                    <span><IntlMessages id="menu.left.elibrary" /></span></Link>
                </Menu.Item>
              : null
            }

            {
              permission && (
                permission.includes("AR_DASHBOARD_COURSE_HO") ||
                permission.includes("AR_DASHBOARD_COURSE_BRANCH") ||                 
                permission.includes("AR_DASHBOARD_ELIBRARY_HO")
              ) ?
                  <SubMenu key="dashboard" popupClassName={getNavStyleSubMenuClass(navStyle)}
                  title={<span> <i className="icon icon-dasbhoard" />
                    <span><IntlMessages id="menu.left.dashboard" /></span></span>}>
                    
                    {
                      permission && (
                        permission.includes("AR_DASHBOARD_COURSE_HO") ||
                        permission.includes("AR_DASHBOARD_COURSE_BRANCH")
                      ) ?
                          <Menu.Item key="lms/dashbaord/course">
                            <Link to="/lms/dashbaord/course">
                              <i className="icon icon-data-entry" />
                              <span><IntlMessages id="menu_sub.left.dashboard.training" /></span>
                            </Link>
                          </Menu.Item>
                      : null
                    }

                    {
                      permission && (
                        permission.includes("AR_DASHBOARD_ELIBRARY_HO")
                      ) ?
                          <Menu.Item key="lms/dashbaord/elibrary">
                            <Link to="/lms/dashbaord/elibrary">
                              <i className="icon icon-data-entry" />
                              <span><IntlMessages id="menu_sub.left.dashboard.elibrary" /></span>
                            </Link>
                          </Menu.Item>
                      : null
                    } 
                  </SubMenu>
              : null
            }


            {
              permission && (
                permission.includes("AR_TEAM_MANAGEMENT")
              ) ?
                <Menu.Item key="lms/team-mgnt/index">
                  <Link to="/lms/team-mgnt"><i className="icon icon-family" />
                    <span><IntlMessages id="menu.left.team_management" /></span></Link>
                </Menu.Item>
              : null
            }

            {
              permission && (
                permission.includes("AR_SYSCONFIG_USERMGMT_MANAGE_ROLE") ||
                permission.includes("AR_SYSCONFIG_USERMGMT_MANAGE_PERMISSION") || 
                permission.includes("AR_SYSCONFIG_USERMGMT_MANAGE_USER") ||
                permission.includes("AR_SYSCONFIG_ELIBRARYMGMT_MANAGE_CATEGORY") ||
                permission.includes("AR_SYSCONFIG_ELIBRARYMGMT_MANAGE_BOOK") || 
                permission.includes("AR_SYSCONFIG_COURSE_MANAGE_MASTER_DATA") ||
                permission.includes("AR_SYSCONFIG_COURSE_MANAGE_COMPULSORY") ||
                permission.includes("AR_SYSCONFIG_COURSE_MANAGE_PRETRAINING") ||
                permission.includes("AR_SYSCONFIG_COURSE_MANAGE_NEWSTAFF") ||
                permission.includes("AR_SYSCONFIG_COURSE_MANAGE_COURSE_OTHER")
              ) ?
                <SubMenu key="setting" popupClassName={getNavStyleSubMenuClass(navStyle)}
                  title={<span><i className="icon icon-setting" /><span><IntlMessages
                    id="menu.left.system_setting" /></span></span>}>
                    {
                      permission && (
                        permission.includes("AR_SYSCONFIG_USERMGMT_MANAGE_ROLE") ||
                        permission.includes("AR_SYSCONFIG_USERMGMT_MANAGE_PERMISSION") || 
                        permission.includes("AR_SYSCONFIG_USERMGMT_MANAGE_USER")
                      ) ?
                        <SubMenu key="user-managemnt" popupClassName={getNavStyleSubMenuClass(navStyle)}
                          title={<span><IntlMessages
                            id="menu_sub.left.user_management" /></span>}>
          
                            {
                              permission && (
                                permission.includes("AR_SYSCONFIG_USERMGMT_MANAGE_ROLE")
                              ) ?
                                <Menu.Item key="lms/settings/roles">
                                  <Link to="/lms/settings/roles">
                                    <span><IntlMessages
                                      id="menu_sub.left.manage_role" /></span></Link>
                                </Menu.Item>
                              : null
                            }
                
                            {
                              permission && (
                                permission.includes("AR_SYSCONFIG_USERMGMT_MANAGE_PERMISSION")
                              ) ?
                                <Menu.Item key="lms/settings/permissions">
                                  <Link to="/lms/settings/permissions">
                                    <span><IntlMessages
                                      id="menu_sub.left.manage_permission" /></span></Link>
                                </Menu.Item>
                              : null
                            }
                
                            {
                              permission && (
                                permission.includes("AR_SYSCONFIG_USERMGMT_MANAGE_USER")
                              ) ?
                                <Menu.Item key={'lms/settings/users'}>
                                  <Link to="/lms/settings/users">
                                    <span><IntlMessages
                                      id="menu_sub.left.manage_user" /></span></Link>
                                </Menu.Item>
                              : null
                            }
                        </SubMenu>
                      : null
                    }
                  
                    {
                      permission && (
                        permission.includes("AR_SYSCONFIG_ELIBRARYMGMT_MANAGE_CATEGORY") ||
                        permission.includes("AR_SYSCONFIG_ELIBRARYMGMT_MANAGE_BOOK")
                      ) ?
                        <SubMenu key="elibrary-managemnt" popupClassName={getNavStyleSubMenuClass(navStyle)}
                          title={<span><IntlMessages
                            id="menu_sub.left.elibrary_management" /></span>}>
                          
                            {
                              permission && (
                                permission.includes("AR_SYSCONFIG_ELIBRARYMGMT_MANAGE_CATEGORY")
                              ) ?
                                <Menu.Item key="lms/settings/book-categorys">
                                  <Link to="/lms/settings/book-categorys">
                                    <span><IntlMessages
                                      id="menu_sub.left.manage_elibrary_category" /></span></Link>
                                </Menu.Item>
                              : null
                            }
                
                            {
                              permission && (
                                permission.includes("AR_SYSCONFIG_ELIBRARYMGMT_MANAGE_BOOK")
                              ) ?
                                <Menu.Item key="lms/settings/books">
                                  <Link to="/lms/settings/books/index">
                                    <span><IntlMessages id="menu_sub.left.manage_elibrary_book" /></span></Link>
                                </Menu.Item>
                              : null
                            }
                        </SubMenu>
                      : null
                    }
                  
                    {
                      permission && (
                        permission.includes("AR_SYSCONFIG_COURSE_MANAGE_MASTER_DATA") ||
                        permission.includes("AR_SYSCONFIG_COURSE_MANAGE_COMPULSORY") ||
                        permission.includes("AR_SYSCONFIG_COURSE_MANAGE_PRETRAINING") ||
                        permission.includes("AR_SYSCONFIG_COURSE_MANAGE_NEWSTAFF") ||
                        permission.includes("AR_SYSCONFIG_COURSE_MANAGE_COURSE_OTHER")
                      ) ?
                        <SubMenu key="course-managemnt" popupClassName={getNavStyleSubMenuClass(navStyle)}
                          title={<span><IntlMessages id="menu_sub.left.course_management" /></span>}>
                          
                            {
                              permission && (
                                permission.includes("AR_SYSCONFIG_COURSE_MANAGE_MASTER_DATA")
                              ) ?
                                <Menu.Item key="lms/settings/courses/master">
                                  <Link to="/lms/settings/courses/master">
                                    <span><IntlMessages id="menu_sub.left.manage_course_master" /></span></Link>
                                </Menu.Item>
                              : null
                            }
          
                            {
                              permission && (
                                permission.includes("AR_SYSCONFIG_COURSE_MANAGE_COMPULSORY")
                              ) ?
                                <Menu.Item key="lms/settings/courses/compulsorys">
                                  <Link to="/lms/settings/courses/compulsory/index">
                                    <span><IntlMessages id="menu_sub.left.manage_course_compulsory" /></span>
                                  </Link>
                                </Menu.Item>
                              : null
                            }
          
                            {
                              permission && (
                                permission.includes("AR_SYSCONFIG_COURSE_MANAGE_PRETRAINING")
                              ) ?
                                <Menu.Item key="lms/settings/courses/pretraining">
                                  <Link to="/lms/settings/courses/pretraining/index">
                                    <span><IntlMessages id="menu_sub.left.manage_course_pretraining" /></span>
                                  </Link>
                                </Menu.Item>
                              : null
                            }
                            
                            {
                              permission && (
                                permission.includes("AR_SYSCONFIG_COURSE_MANAGE_NEWSTAFF")
                              ) ?
                                <Menu.Item key="lms/settings/courses/new-staff">
                                  <Link to="/lms/settings/courses/new-staff/index">
                                    <span><IntlMessages id="menu_sub.left.manage_course_newstaff" /></span>
                                  </Link>
                                </Menu.Item>
                              : null
                            }
          
                            {
                              permission && (
                                permission.includes("AR_SYSCONFIG_COURSE_MANAGE_COURSE_OTHER")
                              ) ?
                                <Menu.Item key="lms/settings/courses/other">
                                  <Link to="/lms/settings/courses/other/index">
                                    <span><IntlMessages id="menu_sub.left.manage_course_request_training" /></span>
                                  </Link>
                                </Menu.Item>
                              : null
                            }
                        </SubMenu>
                      : null
                    }
                </SubMenu>
              : null
            }
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};





// <Menu.Item key="lms/settings/request-training/type">
// <Link to="/lms/settings/request-training/type">
//   <span>Manage Type</span></Link>
// </Menu.Item>
// <Menu.Item key="lms/settings/request-training/program">
// <Link to="/lms/settings/request-training/program">
//   <span>Manage Program</span></Link>
// </Menu.Item>
// <Menu.Item key="lms/settings/request-training/item-type">
// <Link to="/lms/settings/request-training/item-type">
//   <span>Manage Item Type</span></Link>
// </Menu.Item>
// <Menu.Item key="lms/settings/request-training/delivary-method">
// <Link to="/lms/settings/request-training/delivary-method">
//   <span>Manage Delivary Method</span></Link>
// </Menu.Item>
// <Menu.Item key="lms/settings/request-training/competency">
// <Link to="/lms/settings/request-training/competency">
//   <span>Manage Competency</span></Link>
// </Menu.Item>
// <Menu.Item key="lms/settings/request-training/subcompetency">
// <Link to="/lms/settings/request-training/subcompetency">
//   <span>Manage SubCompetency</span></Link>
// </Menu.Item>
// <Menu.Item key="lms/settings/request-training/speaker">
// <Link to="/lms/settings/request-training/speaker">
//   <span>Manage Speaker</span></Link>
// </Menu.Item>





// <SubMenu key="pre-training-managemnt" popupClassName={getNavStyleSubMenuClass(navStyle)}
// title={<span><IntlMessages
//   id="sidebar.group.menu.cms.pre-training-management" /></span>}>

// </SubMenu>

// <SubMenu key="request-training-managemnt" popupClassName={getNavStyleSubMenuClass(navStyle)}
// title={<span>Request Training Management</span>}>


// <Menu.Item key="lms/settings/request-training/course">
//   <Link to="/lms/settings/request-training/course">
//     <span>Manage Course</span></Link>
// </Menu.Item>
// </SubMenu>


/*
//// <Menu.Item key="announcement">
// <Link to="/"><i className="icon icon-email" />
//   <span><IntlMessages id="sidebar.menu.announcement" /></span></Link>
// </Menu.Item>

// <Menu.Item key="calendar">
// <Link to="/"><i className="icon icon-calendar" />
//   <span><IntlMessages id="sidebar.menu.calendar" /></span></Link>
// </Menu.Item>
*/

SidebarContent.propTypes = {};
export default SidebarContent;


