import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER
} from "constants/ActionTypes";
import {showAuthMessage, userSignInSuccess, userSignOutSuccess, userSignUpSuccess} from "../../appRedux/actions/Auth";

import axios from 'axios';
import { parseJwt } from 'util/Helpers';


// new implement admin authentication
const signInAdminRequest = async (username, password, use_adfs, remember_me) => {
  const url = process.env.REACT_APP_UPLOAD_ENDPOINT + '/auth/login';
  const payload = {
    username: username,
    password: password,
    use_adfs,
    keep_logged_in: (remember_me)? true : false
  };

  return await axios.post(url, payload)
    .then(response => response.data)
    .catch(error => error.response);
};


// 1
function* signInUserWithEmailPassword({payload}) {
  const { username, password, remember_me} = payload;
  try {
    const use_adfs = process.env.USE_ADFS === 'true';
    let response;
    if (!use_adfs) {
      response = yield call(signInAdminRequest, username, password, use_adfs, remember_me);
    } else {
      //TO DO ADFS
    }

    if (response != undefined && response.data != undefined && response.data.error != undefined) {
      yield put(showAuthMessage(`(${response.data.error.code}) ${response.data.error.message}`));
    } else {
      if (response.accessToken == undefined) {
        yield put(showAuthMessage(`(00000) System error, Please sign in again.`));
      } else {
        localStorage.setItem('token', response.accessToken);
        yield put(userSignInSuccess(response.accessToken));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    localStorage.clear();
    yield put(userSignOutSuccess(signOutUser));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(signOutUser)
  ]
  );
}
