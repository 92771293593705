import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Master from './Master/routes';
import GroupsCompulsory from './Groups/Compulsory/routes';
import GroupsPreTraining from './Groups/PreTraining/routes';
import GroupsNewStaff from './Groups/NewStaff/routes';
import GroupsOther from './Groups/Other/routes';

const PreTraining = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/master`} />
    <Route path={`${match.url}/master`} component={Master} />
    <Route path={`${match.url}/compulsory`} component={GroupsCompulsory} />
    <Route path={`${match.url}/pretraining`} component={GroupsPreTraining} />
    <Route path={`${match.url}/new-staff`} component={GroupsNewStaff} />
    <Route path={`${match.url}/other`} component={GroupsOther} />
  </Switch>
);
export default PreTraining;


