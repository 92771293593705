import React from "react";
import { useDispatch } from "react-redux";
import { Avatar, Popover } from "antd";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { parseJwt } from 'util/Helpers';
import { userSignOut } from "appRedux/actions/Auth";

const UserInfo = () => {


  const dispatch = useDispatch();
  // <li className="gx-media gx-pointer">My Classroom</li>
  // <li className="gx-media gx-pointer">My Course</li>

  let accessToken = localStorage.getItem('token');
  const { data } = (accessToken) ? parseJwt(accessToken) : { data : { permission: []}};
  const { permission } = data;


  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li className="gx-media gx-pointer">
        <Link to="/lms/my-profile">
          <span><IntlMessages id="shortcut_circle.top_right.myprofile" /></span>
        </Link>
      </li>

      {
        permission && (
          permission.includes("AR_COMPULSORY_COURSELIST_TAB") ||
          permission.includes("AR_COMPULSORY_MYCOURSE_TAB") 
        ) ?
          <li className="gx-media gx-pointer">
            <Link to="/lms/compulsory?tab=my-course">
              <span><IntlMessages id="shortcut_circle.top_right.mycompulsory" /></span>
            </Link>
          </li>
        : null
      }

      {
        permission && (
          permission.includes("AR_PRETRAINING_COURSELIST_TAB") ||
          permission.includes("AR_PRETRAINING_MYCOURSE_TAB") 
        ) ?
          <li className="gx-media gx-pointer">
            <Link to="/lms/pre-training?tab=my-course">
              <span><IntlMessages id="shortcut_circle.top_right.mypretraining" /></span>
            </Link>
          </li>
        : null
      }

      {
        permission && (
          permission.includes("AR_LIBRARY_BOOKLIST_TAB") ||
          permission.includes("AR_LIBRARY_MYBOOK_TAB")
        ) ?
          <li className="gx-media gx-pointer">
            <Link to="/lms/elibrary?tab=my-book">
              <span><IntlMessages id="shortcut_circle.top_right.mybook" /></span>
            </Link>
          </li>
        : null
      }

      
      
      
      <li className="gx-media gx-pointer" onClick={() => dispatch(userSignOut())}>
          <span><IntlMessages id="shortcut_circle.top_right.logout" /></span>
      </li>
    </ul>
  );

  return (
    <Popover overlayClassName="gx-popover-horizantal topNavUserProfile" placement="bottomRight" content={userMenuOptions}
      trigger="click">
      <Avatar src={"https://via.placeholder.com/150"}
        className="gx-avatar gx-pointer" alt="" />
    </Popover>
  )
}

export default UserInfo;
