import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import asyncComponent from "./../../util/asyncComponent";
import Settings from "./Settings";
import TeamMgnt from "./TeamManagement/routes";
import Dashboard from "./Dashboard/routes";

const LMS = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/homepages`} />
    <Route path={`${match.url}/homepages`} component={asyncComponent(() => import('./HomePages/index'))} />
    <Route path={`${match.url}/settings`} component={Settings} />
    <Route path={`${match.url}/my-profile`} component={asyncComponent(() => import('./Owner/MyProfile/index'))} />
    <Route path={`${match.url}/my-book`} component={asyncComponent(() => import('./Owner/MyBook/index'))} />
    <Route path={`${match.url}/announcements`} component={asyncComponent(() => import('./Owner/Announcements/index'))} />
    <Route path={`${match.url}/calendar`} component={asyncComponent(() => import('./Owner/Calendar/index'))} />
    <Route path={`${match.url}/compulsory`} component={asyncComponent(() => import('./Compulsory/index'))} />
    <Route path={`${match.url}/learning-journey`} component={asyncComponent(() => import('./LearningJourney/index'))} />
    <Route path={`${match.url}/pre-training`} component={asyncComponent(() => import('./PreTraining/index'))} />
    <Route path={`${match.url}/elibrary`} component={asyncComponent(() => import('./E-Library/index'))} />
    <Route path={`${match.url}/team-mgnt`} component={TeamMgnt} />
    <Route path={`${match.url}/dashbaord`} component={Dashboard} />

    <Route path={`${match.url}/adfs/logout/callback`} component={asyncComponent(() => import('./Authen/adfs-logout'))} />
  </Switch>
);

export default LMS;


// ENV ADFS_ISSUER https://has-front-dev.scg-wedo.tech
// ENV ADFS_CALLBACK_URL https://has-front-dev.scg-wedo.tech/scg-adfs/authen/callback