import React, { useState } from "react";
import { Layout, Popover } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";
import { switchLanguage, toggleCollapsedSideNav } from "../../appRedux/actions/Setting";
import SearchBox from "components/SearchBox";
import UserInfo from "./UserInfo";
import AppNotification from "components/AppNotification";
import MailNotification from "components/MailNotification";
import Auxiliary from "util/Auxiliary";


import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE } from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";

const { Header } = Layout;

const Topbar = () => {

  const { locale, navStyle } = useSelector(({ settings }) => settings);
  const { navCollapsed, width } = useSelector(({ common }) => common);
  const { searchText, setSearchText } = useState('');
  const dispatch = useDispatch();

  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language =>
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
            dispatch(switchLanguage(language))
          }>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        )}
      </ul>
    </CustomScrollbars>);

  const updateSearchChatUser = (evt) => {
    setSearchText(evt.target.value);
  };
  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
        <div className="gx-linebar gx-mr-3">
          <i className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div> : null}

      <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
        <img alt="" src={require("assets/images/w-logo.png")} /></Link>


      <ul className="gx-header-notifications gx-ml-auto">
        {width >= TAB_SIZE ? null :
          <Auxiliary>
            <li className="gx-calendar">
              <Link to="/lms/calendar" className="gx-topnav-calendar">
                <span className="gx-pointer gx-status-pos gx-d-block">
                  <i className="gx-text-white icon icon-calendar" />
                </span>
              </Link>
            </li>

            <li className="gx-announcement">
              <Link to="/lms/announcements" className="gx-topnav-announcement">
                <span className="gx-pointer gx-status-pos gx-d-block">
                  <i className="gx-text-white icon icon-email" />
                  <span className="gx-status gx-status-rtl gx-small gx-orange" />
                </span>
              </Link>
            </li>
          </Auxiliary>
        }

        <li className="gx-user-nav"><UserInfo /></li>

        {width >= TAB_SIZE ? null :
          <Auxiliary>
            <li className="gx-user-nav"><UserInfo /></li>
          </Auxiliary>
        }
      </ul>
    </Header>
  );
};

export default Topbar;

// <li className="gx-calendar">
// <Link to="/lms/calendar" className="gx-topnav-calendar">
//   <span className="gx-pointer gx-status-pos gx-d-block">
//     <i className="gx-text-white icon icon-calendar" />
//   </span>
// </Link>
// </li>

// <li className="gx-announcement">
// <Link to="/lms/announcements" className="gx-topnav-announcement">
//   <span className="gx-pointer gx-status-pos gx-d-block">
//     <i className="gx-text-white icon icon-email" />
//     <span className="gx-status gx-status-rtl gx-small gx-orange" />
//   </span>
// </Link>
// </li>